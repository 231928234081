import React from 'react';
import maintainence from "../images/maintainenceicon.jpg"

// import { Outlet } from 'react-router';

const Services = () => {
    return (
        <div className='my-5 py-3'>
        {/* <Outlet/> */}
        <img style={{height: "220px", width: "220px"}} src={maintainence} alt='Construction'/>
        <h1 className='my-3 pt-3 fw-bold'>Under Construction</h1>
        <h4 className='px-5 fw-normal fst-italic' style={{marginRight: "350px", marginLeft: "350px"}}>Our website is under construction, we are working very hard to give you the best experience with this one.</h4>
    </div>
         
    );
};

export default Services;