import React from 'react';
import maintainence from "../images/maintainenceicon.jpg"
// import { Card, CardGroup } from 'react-bootstrap';
// import "./Team.css";
// import technical from "../images/technical.png";
// import development from '../images/development.png';
// import qualitycontrol from '../images/quality-control.png';
// import advisory from "../images/advisory.png";

const Team = () => {
    return (
        <div className='my-5 py-3'>
            <img style={{height: "220px", width: "220px"}} src={maintainence} alt='Construction'/>
            <h1 className='my-3 pt-3 fw-bold'>Under Construction</h1>
            <h4 className='px-5 fw-normal fst-italic' style={{marginRight: "350px", marginLeft: "350px"}}>Our website is under construction, we are working very hard to give you the best experience with this one.</h4>
        </div>
        // <div className = "team my-5 mx-5 py-4">
        // <CardGroup className = "grid">
        //     <Card>
        //         <Card.Img variant="top" src={technical} className = "technical"/>
        //         <Card.Body>
        //         <Card.Title className = "text">Technical Team</Card.Title>
        //         <Card.Text className = "text-start">
        //         &#8226; Provides instant technological services to the farmers according to their complaints and requirement.<br/>
        //         &#8226; Highly trained and qualified  personnel from individual  technical background.
        //         </Card.Text>
        //         </Card.Body>
        //     </Card>
        //     <Card>
        //         <Card.Img variant="top" src={development} className = "development" />
        //         <Card.Body>
        //         <Card.Title className = "text">R & D Team</Card.Title>
        //         <Card.Text className = "text-start">
        //         &#8226; Analyses critical technological problems that need further investigation.<br/>
        //         &#8226; Explores profoundly by specific fact-finding procedures. <br/>
        //         &#8226; Stand by for supporting the expert technical team. 
        //         </Card.Text>
        //         </Card.Body>
        //     </Card>
        //     <Card>
        //         <Card.Img variant="top" src= {qualitycontrol} className = "quality" />
        //         <Card.Body>
        //         <Card.Title className = "text">Quality Control Team</Card.Title>
        //         <Card.Text className = "text-start">
        //         &#8226; Verification of project outputs by trial, review & inspection. <br/>
        //         &#8226; Ensures that the service meets specific acceptance criteria defined. <br/>
        //         &#8226; Focused on preventing problems being occurred during the service.
        //         </Card.Text>
        //         </Card.Body>
        //     </Card>
        //     <Card>
        //         <Card.Img variant="top" src={advisory} className = "advisory" />
        //         <Card.Body>
        //         <Card.Title className = "text">Advisory Team</Card.Title>
        //         <Card.Text className = "text-start">
        //         &#8226; When technical team is unable to solve the problem, the problem is handover to the advisory team.<br/>
        //         &#8226; This team is comprised with experts/consultants from national and international level.

        //         </Card.Text>
        //         </Card.Body>
        //     </Card>
        //     </CardGroup>
              
        // </div>
    );
};

export default Team;