import React from 'react';
import './NotFound.css';
import error from '../images/404.jpg'
const NotFound = () => {
    return (
        <div>
            <div className = "my-1">
            <img src = {error} alt = "Not Found" className='error'/>
            <div>
            <h3>404 page not found</h3>
            <p>We are sorry but the page you are looking for does not exist.</p>
            </div>
        </div>
        </div>
    );
};

export default NotFound;