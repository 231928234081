import React from 'react';
import './About.css'
const About = () => {
    return (
        <div className = "mx-4 my-3 py-3">
            <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-start">
                         <h4 className = "heading" id='head'>History</h4>
                            <div className = "underline"></div>
                            <p className = "text-start lh-lg">The economy of our country is mostly depended on agriculture. With the development of information and technology, our agricultural sector has been developing. But farmers and industries are facing some crucial problems to access enough, useful, and accurate information about farming in time. From this concern, Agro Solution has started its journey in 2015 as a technical service center, an advanced and specialist organization in Bangladesh to provide technical support and farm related services, hands on training, knowledge sharing session, research & development, and other need-based services to Agro-based sectors (aqua, poultry and livestock).  In perspective of our Bangladesh the participations of women in agricultural sector are very few Agro Solution is also working to empower the participation of women in advanced agricultural sector.</p>
                            <h4 className = "heading" id='head'>Mission</h4>
                            <div className = "underline"></div>
                            <p className = "text-start lh-lg">To contribute for farmers’ success as well as growing profitability through superior customer service, innovation, quality, traceability, and commitment to the sustainable growth in agricultural sector (livestock, poultry, fish etc.) with the aim of safe food in Bangladesh.</p>
                            <h4 className = "heading" id='heading'>Vision</h4>
                            <div className = "underline"></div>
                            <p className = "text-start lh-lg">Our vision is to develop a complete and single point solution services for Agro-based sector where you can find the best solutions of your ultimate challenges. </p>  
                        </div>    
                    </div>
            </div>
        </div>   

    );
};

export default About;