import React from 'react';
import './Banner.css'
import call from '../../images/call.png'
// import home from '../../images/home.png';
// import success from "../../images/success.png"
const Banner = () => {
    return (
        <div>
            <section className="mb-4 my-1 py-3 pt-md-4 " id="solution">
            <div className="container">
                <div className="row align-items-center d-flex">
                     <div className="col-lg-6 col-12">
                        <div className="content-main">
                        <h3 className='text-start agro'><i>A<span className='ag'>g</span>ro Solution</i></h3>
                        <h5 className='text-start mx-4 future lh-lg'><i>For The Better Future</i></h5>
                            <p className="pt-4 text-start lh-lg">Agro Solution is a dedicated technical service center of innovative services for poultry, livestock, and aquaculture feed industries with technical supports to the farmer. We offer IT based technological solution for animal nutrition, feed process management, raw materials evaluation and farm related technical support that work together to create a value for agricultural sector in an affordable, efficient, and sustainable way. We work on to find the challenges of industries and farms with market analysis to find the best opportunity for a sustainable development in agricultural sector in a profitable way. We are trying to scale up the small and medium scale industries and farms by embracing new technologies and inventions with technical field input. 
                            </p>
                        </div>
                    </div>
                    <div className="web-banner  col-lg-6 pt-3 col-12">
                        <img className="img-fluid call-center" src={call} alt="call"/>
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
};

export default Banner;