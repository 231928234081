import React from 'react';
// import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    return (
        <div className = "footer text-bold">
            <div className = "container">
                <div className = "row pt-2">
                    <div className="col-lg-6">
                        <p className = "text-start"><b><i className="fas fa-map-marker-alt"></i> Agro Solution</b><br/>
                        House-13/B (6th floor), Road-99, Gulshan-2, Dhaka-1212.</p>
                    </div>
                    <div className="col-lg-3">
                        <p className = "text-start"><i className="fas fa-envelope"></i> info@agrosolution.com.bd <br/>
                        <i className="fas fa-phone-alt"></i> 09614501901-3</p>
                        
                    </div>
                    <div className="col-lg-3">
                    <div>
                    <ul className="d-lg-flex justify-content-end align-items-end list-unstyled">
                        <li className = "px-2"><a className = "link" href = "https://www.facebook.com/agrosolutionbd/"><i className="fab fa-facebook"></i></a></li>
                        <li className = "px-2"><a className = "link" href = "/"><i className="fab fa-twitter"></i></a></li>
                        <li className = "px-2"><i className="fab fa-whatsapp"></i></li>
                        <li className = "px-2"><i className="fab fa-youtube"></i></li>
                        <li className = "px-2"><a className = "link" href = "https://www.linkedin.com/company/agrosolution/"><i className="fab fa-linkedin-in"></i></a></li>
                    </ul>
                    {/* <p className = "copy mx-3">&copy;2021. Agro Solution. All rights reserved.</p> */}
                    {/* <ul className = "list-unstyled justify-content-end align-items-end">
                        <li><Link to = "/">Terms & Condition</Link></li>
                    </ul> */}
                    </div>
                    </div>
                        <p className = "copy">&copy;2023. Agro Solution. All rights reserved.</p>
                    </div>
            </div>
        </div>
    );
};

export default Footer;