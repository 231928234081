import React from 'react';
import { Container, Nav, Navbar, NavbarBrand, NavDropdown } from 'react-bootstrap';
import './Header.css'
// import newlogo from '../images/newlogo.png';
import Logo from '../images/Agro Logo.png'

const Header = () => {
    return (
        <>
         <Navbar expand="lg" variant="light" bg="light" sticky="top">
            <Container>
                <NavbarBrand href="/">
                <img src= {Logo} alt ="logo" className='logo'></img>
                </NavbarBrand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="menu">
                        <Nav.Link href="/" style={{color:"navy"}}>Home</Nav.Link>
                        <Nav.Link href="/about" style= {{color:"navy"}}>About</Nav.Link>
                        <Nav.Link href="/services"style= {{color:"navy"}}>Services</Nav.Link> 
                        <Nav.Link href="/team"style= {{color:"navy"}}>Team</Nav.Link>                        
                        <Nav.Link href="/contact"style= {{color:"navy"}}>Contact</Nav.Link>
                        {/* <NavDropdown title="Services" id="collasible-nav-dropdown" style={{color:"navy"}}>
                            <NavDropdown.Item href="/services/technical"style={{color:"navy"}}>Farm Related Technical Support
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/services/callcenter" style={{color:"navy"}}>Agro based Call center</NavDropdown.Item>
                            <NavDropdown.Item href="/services/consultancy"style={{color:"navy"}}>Industry based Consultancy and related Service
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/services/research"style={{color:"navy"}}>Research & Development
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/services/workshop"style={{color:"navy"}}>Training and Workshop
                            </NavDropdown.Item>
                        </NavDropdown> */}
                       {/* <Nav.Link to ='/login' style = {{color: "navy"}}>Login</Nav.Link>
                       <Nav.Link href='/register' style = {{color: "navy"}}>Sign Up</Nav.Link> */}
                    </Nav>               
                </Navbar.Collapse>
            </Container>
         </Navbar>
  
        </>
    );
};

export default Header;