import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';

const Contact = () => {
    const [value, setValue] = useState()

    return (
        <div id ="contact-form" className = "py-4 mb-4 mx-5">
            <div className = "container">
            <div className = "row">
                <div className="col-lg-6">
                    <div className = "text-start font-weight-bold py-5 my-5">
                        <h4 id='head'>Get In Touch</h4>
                        <br/>
                        <p><i className="fas fa-map-marker-alt"></i> House-13/B (6th floor), Road-99, Gulshan-2, Dhaka-1212</p>
                        <p><i className="fas fa-envelope"></i> info@agrosolution.com.bd</p>
                        <p><i className="fas fa-phone-alt"></i> 09614501901-3</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card">
                        <div className="card-tittle">
                        <h4 id='head'>Contact Us</h4>
                        </div>
                        <div className="card-body">
                        <Form>
                            <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" placeholder="Username" required/>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="exampleForm.ControlInput2">
                                <Form.Control type="email" placeholder="Enter email" required />
                            </Form.Group>
                            <Form.Group className = "form-control mb-3">
                            <PhoneInput className = "phone"
                                placeholder="Enter phone number"
                                value={value}
                                onChange={setValue}/>
                            </Form.Group>
                            <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" rows={3} placeholder = "Message" />
                            </Form.Group>
                            <Button type="submit" className = "form-control" style = {{backgroundColor: "#19446E"}}>
                                Send Message
                            </Button>
                        </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default Contact;