import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Home from './components/Home/Home/Home';
import NotFound from './components/NotFound/NotFound';
import Services from './components/Services/Services';
import Team from './components/Team/Team';
// import Callcenter from './components/Services/Callcenter/Callcenter';
// import Consultancy from './components/Services/Consultancy/Consultancy';
// import Services from './components/Services/Services';
// import Research from './components/Services/Research/Research';
// import Technical from './components/Services/Technical/Technical';
// import Workshop from './components/Services/Workshop/Workshop';



function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <Header/>
        <Routes>
        <Route path = "/" element = {<Home/>}/>
          <Route path = "/home" element = {<Home/>}/>
          <Route path = "/about" element = {<About/>}/>
          <Route path = "/contact" element = {<Contact/>}/>
          <Route path = "/services" element = {<Services/>}></Route>
          <Route path = "/team" element = {<Team/>}></Route>
          <Route path = "*" element = {<NotFound/>}/>
          {/* <Route path = "/services/*" element = {<Services/>}>
            <Route path = "callcenter" element = {<Callcenter/>}>Agro Based Call Center</Route>
            <Route path = "consultancy" element = {<Consultancy/>}>Consultancy</Route>
            <Route path = "technical" element = {<Technical/>}>Farm Related Technical Support</Route>
            <Route path = "research" element = {<Research/>}>Research & Development(R & D)</Route>
            <Route path = "workshop" element = {<Workshop/>}>Training and workshop</Route>
          </Route> */}
           {/* <Route path = "/activities" element = {<Activities/>}/> */}

          {/* <Route path = "fish" element = {<Fish/>}>Fish</Route>
          <Route path = "poultry" element = {<Poultry/>}>Poultry</Route> */}
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
